import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import PralineListDoos from '@/views/listViews/PralineListDoos.vue'
import AutomaatRij from '@/components/AutomaatRij.vue'
import Video from '@/components/CustomVideo.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView

  },
  {
    path: '/video',
    name: 'video',
    component: Video
  },
  {
    path: '/automaat/rij/:id',
    name: 'rijAutomaat',
    component: AutomaatRij,
    props: true
  },
  {
    path: '/pralines',
    name: 'Pralines',
    component: PralineListDoos,
    props: require('./objects/pralines.json')

  },
  {
    path: '/dessertpralines',
    name: 'Dessertpralines',
    component: PralineListDoos,
    props: require('./objects/dessertpralines.json')
  },
  {
    path: '/suikervrij',
    name: 'Suikervrij',
    component: PralineListDoos,
    props: require('./objects/suikervrij.json')
  },
  {
    path: '/paasdoosje',
    name: 'Paasdoosje',
    component: PralineListDoos,
    props: require("./objects/paasdoos.json"),
  },
  {
    path: '/paashaasjes',
    name: 'Paashaasjes',
    component: PralineListDoos,
    props: require("./objects/paashaasjes.json"),
  },
  {
    path: '/paaseitjes',
    name: 'Paaseitjes',
    component: PralineListDoos,
    props: require("./objects/paaseitjes.json"),
  }
  /**{
    path: '/valentijn2',
    name: 'Hartjes',
    component: PralineListDoos,
    props: require('./objects/hartjes.json')
  },
  {
    path: '/valentijn1',
    name: 'I love you',
    component: PralineListDoos,
    props: require('./objects/love.json')
  }?
  {
    path: '/herfst',
    name: 'Herfst',
    component: PralineListDoos,
    props: require("./objects/herfst.json"),
  },
  {
    path: '/zomervakantie',
    name: 'Zomervakantie',
    component: PralineListDoos,
    props: require("./objects/zomer.json"),
  },
  {
    path: '/vaderdag',
    name: 'Vaderdag',
    component: PralineListDoos,
    props: require("./objects/vaderdag.json"),
  },
  {
    path: '/moederdag_doosje',
    name: 'Moederdag - doosje',
    component: PralineListDoos,
    props: require("./objects/moederdag.json"),
  },
  {
    path: '/moederdag_hart',
    name: 'Moederdag',
    component: PralineListDoos,
    props: require("./objects/hart.json"),
  },
  {
    path: '/paasmand',
    name: 'Paasmand',
    component: PralineListDoos,
    props: require("./objects/paasmand.json"),
  },
  {
    path: '/valentijn3',
    name: 'Valentijn pralines',
    component: PralineListDoos,
    props: require("./objects/valentijn.json"),
  },
  {
    path: '/beesten',
    name: 'Beestige beestenboel',
    component: PralineListDoos,
    props: require("./objects/beesten.json"),
  },
  {
    path: '/sint24p1',
    name: 'Sintboek',
    component: PralineListDoos,
    props: require("./objects/sintboek.json"),
  },
  {
    path: '/sint24p2',
    name: 'Sintpakket',
    component: PralineListDoos,
    props: require("./objects/sintpakket.json"),
  },
  {
    path: '/feestpralines',
    name: 'Feestpralines',
    component: PralineListDoos,
    props: require('./objects/feestpralines.json')
  },
  {
    path: '/Allcomm',
    name: 'Allcomm',
    component: PralineListDoos,
    props: require('./objects/allcomm.json')
  },
  */

  /**
  {
    path: '/verstuurd',
    name: 'register_done',
    component: RegisterDone
  },
  */
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

function strip (path) {
  const last = path.slice(-1, path.length)
  if (last === '/') { return path.slice(0, -1) }
  return path
}

router.beforeEach(to => {
  // Links mogen niet eindigen op een nummer (bv ../../../1)
  let path = strip(to.fullPath)
  const split = path.split('/')
  if (!isNaN(+split[split.length - 1])) {
    path = split.slice(0, -1).join('/')
  }
  if (!routes.map(el => strip(el.path.split(':')[0])).includes(path)) {
    console.log('Non existing url')
    // trigger a redirection
    return '/'
  }
})

export default router
