<template>
  <div>
    <videoplayer
      class="videoplayer"
      src="Video_tpm.mp4"
      :style="this.videoWidth"
      :muted="false"
      :autoplay="false"
      :controls="true"
      :loop="false"
      :width="width"
      poster="img/poster.png"
      @play="onPlayerPlay"
      @pause="onPlayerPause"
      @ended="onPlayerEnded"
    />
  </div>
</template>
<script>
import videoplayer from './Videoplayer'
export default {
  components: {
    videoplayer
  },
  props: {
    width: { type: Number, required: false, default: 500 }
  },
  data () {
    return {
      time: 0
    }
  },
  methods: {
    onPlayerPlay ({ event, player }) {
      player.setPlaying(true)
    },
    onPlayerPause ({ event, player }) {
      player.setPlaying(false)
    },
    onPlayerEnded ({ event, player }) {
      player.setPlaying(false)
    }
  },
  computed: {
    videoWidth () {
      return {
        width: `${this.width}px;`
      }
    }
  }
}
</script>

<!--<style>-->
<!--.videoplayer {-->
<!--  width: 500px;-->
<!--}-->

<!--</style>-->
