
import SvgIcon from '@jamescoyle/vue-icon'; // Keep this import
import { mdiFacebook, mdiInstagram, mdiEmail } from '@mdi/js'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Footer',
  data: () => ({
    width: window.innerWidth / 3,
    cols: 6,
    facebook: mdiFacebook,
    instagram: mdiInstagram,
    mail: mdiEmail
  }),
  methods: {
    resize (e) {
      if (window.innerWidth > 500) {
        this.width = window.innerWidth / 3
        this.cols = 6
      } else {
        this.width = window.innerWidth - 50
        this.cols = 12
      }
    }
  },
  mounted () {
    window.addEventListener('resize', this.resize)
    window.dispatchEvent(new Event('resize'))
  },
  unmounted () {
    window.removeEventListener('resize', this.resize)
  }
})
