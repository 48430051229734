
import { defineComponent } from 'vue'
import CustomVideo from '@/components/CustomVideo.vue'

export default defineComponent({

  name: 'HomeView',
  components: {
    CustomVideo
  },
  data: () => ({
    width: window.innerWidth / 3,
    cols: 6,
    smallCols: 4
  }),
  methods: {
    resize () {
      if (window.innerWidth > 500) {
        this.width = window.innerWidth / 3
        this.cols = 6
        this.smallCols = 4
      } else {
        this.width = window.innerWidth - 50
        this.cols = 12
        this.smallCols = 12
      }
    }
  },
  mounted () {
    window.addEventListener('resize', this.resize)
    window.dispatchEvent(new Event('resize'))
  },
  unmounted () {
    window.removeEventListener('resize', this.resize)
  },
  computed: {
    imgWidth () {
      console.log(this.width)
      return {
        width: `${this.width}px`
      }
    },
    smallImgWidth () {
      return {
        width: `${0.9 * this.width}px`
      }
    },
    videoWidth () {
      if (this.width < 500) {
        return 0.9 * this.width
      } else {
        return 500
      }
    }
  }
})
