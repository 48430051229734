

import { onMounted, onBeforeUnmount, ref, defineComponent } from 'vue'
import router from '@/router'
import PralineCard from '@/components/PralineCard.vue'
import { mdiHeart, mdiEggEaster, mdiRabbitVariant } from '@mdi/js'

export default defineComponent({
  name: 'NavigationBar',
  components: { PralineCard },
  data: () => ({
    isAdminOrSu: false,
    dozen: [],
    heart: mdiHeart,
    egg: mdiEggEaster,
    rabbit: mdiRabbitVariant

  }),
  async beforeCreate () {
    this.isAdminOrSu = false
  },

  setup () {
    const drawer = ref(false)
    const smallScreen = ref(false)

    const goBack = () => {
      return router.back()
    }
    const onResize = () => {
      smallScreen.value = window.innerWidth < 700
    }
    onMounted(async () => {
      onResize()
      window.addEventListener('resize', onResize, { passive: true })
    })
    onBeforeUnmount(() => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', onResize)
      }
    })
    return {
      drawer,
      smallScreen,
      goBack
    }
  },
  methods: {
    goHome () {
      router.push('/')
    }
  }
})
