<template>
    <v-card
      class="d-flex align-center flex-column mb-6 justify-center container-border"
      style="padding: 15px; flex-grow: 1;"
      min-height="500px"
    >
<!--      <div class="ma-2 pa-2">-->
<!--        <p data-test="naam" class="title">{{ this.praline.naam }}</p>-->
<!--      </div>-->
      <div class="ma-2 pa-2">
        <v-img name="img" :src="require(`@/assets/${this.praline.image}`)" width="200px" />
      </div>
      <div class="ma-2 pa-2">
        <p data-test="beschrijving" class="beschrijving">{{this.praline.beschrijving}}</p>
      </div>
      <div class="ma-2 pa-2">
        <p data-test="allergeen" class="allergeen">{{this.praline.allergeen}}</p>
      </div>
      <div class="ma-2 pa-2" v-if="this.isAdminOrSu" align-self="center" cols="12">
        <v-btn  data-test="delete" icon tile style="max-height: 35px; max-width: 35px;" v-on:click="deletePraline">
          <DeleteIcon />
        </v-btn>
      </div>
<!--      <div class="ma-2 pa-2">-->
<!--        <div v-if="!this.showIngredients" @click="this.showIngredients = true" class="ingredientenknop">Toon ingredienten</div>-->
<!--        <div v-else>-->
<!--          <p style="font-size: 18px">{{this.praline.ingredienten}}</p>-->
<!--          <p class="ingredientenknop" @click="this.showIngredients=false">Verberg ingredienten</p>-->
<!--        </div>-->
<!--      </div>-->
    </v-card>
</template>

<script>
import DeleteIcon from '@/components/icons/DeleteIcon.vue'

/**
 * BuildingCard component wordt gebruikt door als props een Object met de volgende keys mee te geven:
 * gebouw: String
 * adres: String
 * status: String
 * efficiency: Number
 */

export default {
  name: 'PralineCard',
  components: { DeleteIcon },
  props: {
    praline: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {
        id: 0,
        naam: '',
        beschrijving: '',
        ingredienten: '',
        image: '',
        doos: 0
      }
    }
  },
  data: () => ({
    showIngredients: false,
    isAdminOrSu: false
  }),
  async beforeCreate () {
    this.isAdminOrSu = false
  }
}

</script>

<style scoped>
.title {
  font-size: 20px;
  font-family: "Bell MT", sans-serif;
}
.v-card-text{
  flex-grow: 1;
}
.beschrijving{
  font-size: 15px;
  font-family: "Bell MT", sans-serif;
  color: #FFEFD8;
}
.allergeen{
  font-weight: bold;
  font-family: "Bell MT", sans-serif;
}
.ingredientenknop{
  text-decoration-line: underline;
  cursor: pointer;
  font-size: 18px;
  color: #0645AD;
}

</style>
