<script>
import router from '@/router'

export default {
  name: 'AutomaatRij',
  async beforeCreate () {
    switch (this.$route.params.id) {
      case '1':
        await router.push({ name: 'Pralines' })
        break
      case '2':
        await router.push({ name: 'Pralines' })
        break
      case '3':
        await router.push({ name: 'Paasdoosje' })
        break
      case '4':
        await router.push({ name: 'Paashaasjes' })
        break
      case '5':
        await router.push({ name: 'Paaseitjes' })
        break
      case '6':
        await router.push({ name: 'Pralines' })
        break
      case '7':
        await router.push({ name: 'Pralines' })
        break
      case '8':
        await router.push({ name: 'Suikervrij' })
        break
      default:
        await router.push({ name: 'home' })
    }
    // location.reload()
  }
}

</script>

<template>
  <h1>This page is a row in the automat.</h1>
  <h1>It needs to re-route to the right page.</h1>
  <h1>If you see this there went something wrong and please contact us.</h1>
  <br>
  <h1 color="secondary">kristel@pralienmachien.be
    <br>www.pralienmachien.be
  </h1>
</template>

<style scoped>

</style>
