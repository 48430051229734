

import { defineComponent } from 'vue'
import NavigationBar from '@/components/NavigationBar.vue'
import { useRouter } from 'vue-router'
import Footer from '@/components/Footer.vue'

export default defineComponent({
  name: 'App',
  async beforeCreate () {
    const noLogin = ['login', 'forgot', 'pralines'] // Pages that can be accessed without logging in
    const router = useRouter()

    router.beforeEach(async (to, from, next) => {
      // const needsLogin = !noLogin.includes(to.name.toString());
      // Authorize session
      // if(!['/', '/sint23p1', '/sint23p2'].includes(to.fullPath)){
      //   await router.push('/')
      // }
      this.navbar = true// user !== null;

      return next()
    })
  },
  components: {
    Footer,
    NavigationBar
  },
  data: () => ({
    navbar: false
  })
})
