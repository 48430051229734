<template>
  <v-container align="center">
   <v-row  align="center" class="d-flex flex-column row">
      <div id="app">
        <h1>{{ this.titel }}</h1>
        <ul :style="gridStyle" class="card-list">
          <li v-for="(praline, index) in this.pralines" :key="index" class="card-item">
            <PralineCard v-bind:praline="praline"/>
          </li>
          <li align="left" v-for="(assortiment, index) in this.assortiment" :key="index" class="card-item">
            <PralineCardAssortiment v-bind:assortiment="assortiment"/>
          </li>
        </ul>
      </div>
    </v-row>
    <v-row align="center">
      <v-col>
        <p class="text-block" style="color: #FFEFD8;">{{this.text}}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PralineCard from '@/components/PralineCard.vue'
import PralineCardAssortiment from '@/components/PralineCardAssortiment.vue'
import router from '@/router'

export default {
  name: 'ListPage',
  components: { PralineCard, PralineCardAssortiment },
  props: {
    titel: {
      type: String,
      default: '',
      color: '#000000'
    },
    pralines: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [{ naam: 'Test', beschrijving: 'test praline', image: 'in.png' }]
    },
    assortiment: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    text: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    isAdmin: false,
    url: '',
    numberOfColumns: Math.floor(window.innerWidth / 400)
  }),
  // beforeRouteUpdate(to, from) {
  //   const doos = Number(to.params.doos)
  //   if (doos !== Number(from.params.doos)) {
  //     this.changeUrl(doos);
  //   }
  // },
  methods: {
    router () {
      return router
    },
    resize () {
      this.numberOfColumns = Math.floor(window.innerWidth / 400)
    },
    changeUrl (doos) {
      this.url = window.location.host + '/pralines/' + doos.toString()
    }
  },
  computed: {
    gridStyle () {
      return {
        gridTemplateColumns: `repeat(${this.numberOfColumns}, minmax(300px, 1fr))`
      }
    }
  },
  mounted () {
    window.addEventListener('resize', this.resize)
  },
  unmounted () {
    window.removeEventListener('resize', this.resize)
  },
  async beforeCreate () {
    this.isAdmin = false
    this.url = window.location.href
  }
}
</script>

<style scoped>
ul {
  list-style-type: none;
}
.card-list {
  display: grid;
  grid-gap: 1em;
}

.card-item {
  padding: 2em;
}

.row {
  padding: 10px;
}

#app {
  background: #ffefd8;
  border-radius: 4px;
  padding: 20px;
  transition: all 0.2s;
}
</style>
